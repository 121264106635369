import request from '@/utils/index'
import qs from 'qs'

export const login = (data: { account_name: string; password: string }) => {
    return request.post('/admin/login', data)
}

export const logout = () => {
    return request.get('/admin/sign_out')
}

export const exportExcel = (data: any) => {
    return request.get(`/admin_api/specimen/export/excel?${qs.stringify(data)}`)
}

export const importExcel = (data: any) => {
    return request.put(`/admin_api/specimen/import/excel`, data)
}

export const importExcelPost = (data: any) => {
    return request.post(`/admin_api/specimen/import/excel`, data)
}

export const importImagesPost = (data: any) => {
    return request.post(`/admin_api/specimen/import/images`, data)
}

export const deleteSpecimen = (app_id: string | number) => {
    return request.post(`/admin/apps/${app_id}/update`, { 'state': false })
}

export const importImage = (data: any) => {
    return request.post(`/admin_api/best_photo_gallery/image/upload`, data)
}

export const uploadAppsImage = (data: any, app_id: string) => {
    if (!app_id) {
        return request.post(`/admin/apps`, data)
    } else {
        return request.post(`/admin/apps/${app_id}/update`, data)
    }
}
