




















import { Component, Vue } from 'vue-property-decorator'
import LayoutHeader from './header.vue'
import LayoutFooter from './footer.vue'
import Breadcrumb from '@/components/breadcrumb.vue'
import SideBar from './sidebar.vue'

@Component({
  components: {
    LayoutHeader,
    LayoutFooter,
    Breadcrumb,
    SideBar
  }
})
export default class dashboard extends Vue {}
