












































import { Vue, Component } from 'vue-property-decorator'
@Component
export default class Sidebar extends Vue {
  get sidebarStatus() {
    return this.$store.state.sidebarStatus
  }

  get isCollapse() {
    return this.$store.state.sidebarStatus === 'closed'
  }

  get activeMenu() {
    const route = this.$route
    const { meta, path } = route
    // if set path, the sidebar will highlight the path you set
    if (meta?.activeMenu) {
      return meta.activeMenu
    }
    return path
  }

  private activeMenuIndex: string = '1-4-1'

  // private isCollapse = false
  private handleOpen(key: string, keyPath: any) {
    console.log(key, keyPath)
  }
  private handleClose(key: string, keyPath: any) {
    console.log(key, keyPath)
  }
}
