














import { Vue, Component } from 'vue-property-decorator'
import Breadcrumb from '@/components/breadcrumb.vue'
import { confirm } from '@/utils/decorator'
import { logout } from '@/api/admin'

@Component({ components: { Breadcrumb } })
export default class Header extends Vue {
  get sidebarStatus() {
    return this.$store.state.sidebarStatus
  }

  private handleCollapseClick() {
    const status = this.sidebarStatus === 'opened' ? 'closed' : 'opened'
    this.$store.commit('setSidebarStatus', status)
  }

  @confirm('确定要注销并退出系统吗？', '提示')
  private async handleLogout() {
    await logout()
    this.$router.push('/login')
  }
}
