import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout2 from '@/layout2/index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // 管理端路由
  {
    path: '/login',
    meta: {
      title: '登录'
    },
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue')
  },
  {
    path: '/dashboard',
    component: Layout2,
    children: [
      {
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
        name: 'Dashboard',
        meta: { title: '首页' }
      }
    ]
    // component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue')
  },
  {
    path: '/apps-management',
    component: Layout2,
    children: [
      {
        path: '/apps-management',
        component: () =>
          import(
            /* webpackChunkName: "apps-management" */ '@/views/apps-management/index.vue'
          ),
        name: 'SpecimenManagement',
        meta: { title: '应用管理' }
      },
      {
        path: '/apps-management/ud-detail',
        component: () =>
          import(
            /* webpackChunkName: "apps-management-detail" */ '@/views/apps-management/ud-detail.vue'
          ),
        name: 'SpecimenManagementUDDetail',
        meta: { title: '标本详情' }
      }
    ]
  },
  {
    path: '/algorithm-management',
    component: Layout2,
    children: [
      {
        path: '/algorithm-management',
        component: () =>
            import(
                /* webpackChunkName: "apps-management" */ '@/views/algorithm-management/index.vue'
                ),
        name: 'AlgorithmManagement',
        meta: { title: '算法管理' }
      },
      {
        path: '/algorithm-management/ud-detail',
        component: () =>
            import(
                '@/views/algorithm-management/ud-detail.vue'
                ),
        name: 'AlgorithmManagementUDDetail',
        meta: { title: '算法编辑' }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    meta: {
      title: '404'
    },
    component: () => import(/* webpackChunkName: "404" */ '@/views/404/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 管理端路由
  const whiteList = [
    'AlgorithmManagement',
    'PhotoManagement',
    'AppsManagementDetail',
    'AppsManagementUDDetail',
    'AlgorithmManagementUDDetail',
    'Dashboard'
  ]
  if (to.meta && to.query.metaTitle) {
    to.meta.title = to.query.metaTitle
  }
  if (to.meta?.title) {
    document.title = to.meta.title
  }
  // 如果token不存在
  if (!localStorage.getItem('authorization')) {
    if (!whiteList.includes(to.name as string)) {
      next()
    } else {
      next('/login')
    }
  }
  next()
})

export default router
